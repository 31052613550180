import React, { useState, useEffect } from "react";
import { NavLink, Redirect } from "react-router-dom";
import axios from "axios";
import Loading from "../../loading/Loading";
import swal from "sweetalert";
import Footer from "../../Footer/Footer";
import ReactPaginate from "react-paginate";
import "../../css/pagination.css";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./consiler.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import '../../css/pagination.css'

export default function ConsolerList() {
  const userid = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  let [pagesize, setpagesize] = useState(25);
  let [selectall, setselectall] = useState(false);
  let [pagenumber, setPagenumber] = useState(0);
  const isAuthenticated = localStorage.getItem("loggedin");
  const [admin, setAdmin] = useState({
    companyname: "",
    fullname: "",
    emailaddress: "",
    registrationnumber: "",
    phonenumber: "",
    address: "",
    profileimage: "",
    country: "",
  });
  // const [visible, setVisible] = useState(false);
  // const [startDates, setStartDates] = useState([]);
  const [showloading, setLoading] = useState(true);
  let [List, setList] = useState([]);
  const [length, setLength] = useState(null);
  const getConsolers = async (pagenumber) => {
    setLoading(true)
    let res = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/api/admin/fetchallconsoler",
      { page: pagenumber + 1, limit: pagesize }
    );
    setLoading(false);
    if (res.data.statuscode == 200) {
      if (res.data.data.length == 0 && pagenumber > 1) {
        setLoading(true);
        getConsolers(pagenumber - 1);
        setPagenumber(pagenumber - 1);
      }
      // console.log("res",res.data.data)
      let data = res?.data?.data.map(item => ({ ...item, selected: false }))
      setList(data);
      setLength(res.data.totalCount);
    }
  };
  // console.log("List: ",List)
  let pageCount = Math.ceil(length / pagesize);
  const changePage = ({ selected }) => {
    console.log(selected);
    setPagenumber(selected);
    pagenumber = selected;
    getConsolers(selected);
    setLoading(true);
  };

  const handleSelectAll = () => {
    if (selectall) {
      setList(List.map((record) => ({ ...record, selected: false })));
    } else {
      setList(List.map((record) => ({ ...record, selected: true })));
    }
    setselectall(!selectall)
  };
  const handleSelectRecord = (id) => {
   let data=
      List.map((record) =>
        record.id === id ? { ...record, selected: !record.selected } : record
      )
      setList(data)
      List=data
      let selectedObject= List.filter(item => item.selected)
       const selectedIds = selectedObject.map(obj => obj._id);
       if(selectedIds.length==List.length){
         setselectall(true)
         selectall=true
       }else{
         setselectall(false)
         selectall=false
       }
  };
  const reloadData = (size) => {
    pagesize = size
    pagenumber = 0
    setPagenumber(0)
    setpagesize(size)
    getConsolers(0)
  }

  const deleteConsolers = () => {
    let selectedObject = List.filter(item => item.selected);
    const selectedIds = selectedObject.map(obj => obj.id);
    if (selectedIds.length === 0) {
      swal("Please select at least one consoler!");
      return;
    }
    swal({
      text: "You want to delete selected consolers ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      console.log(selectedIds)
      if (willDelete) {
        let res = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/admin/delete-many-consoler",
          { ids: selectedIds }
        );
        if (res.data.statuscode === 200) {
          setPagenumber(0)
          pagenumber = 0
          getConsolers(0);
          swal("Selected consolers have been deleted!", {
            icon: "success",
          });
        }
      } else {
        swal("Selected consolers are safe!");
      }
    });
  };


  const deleteConsoler = (id) => {
    swal({
      // title: "Are you sure?",
      text: "You want to delete this consoler ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          let res = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/admin/deleteconsoler",
            { id: id }
          );
          if (res.data.statuscode == 200) {
            getConsolers(pagenumber);
          }
          swal("consoler has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your consoler details is safe!");
        }
      });
   
  };

  const table = (
    <tbody>
      {List &&
        List.map((ele, i) => (
          <tr key={i} className="customTR">
            {/* <td className="customTD">{pagenumber * 5 + i + 1}</td> */}
            <td>
            
              <input
                type="checkbox"
                checked={ele.selected}
                onChange={() => handleSelectRecord(ele.id)}
              />
            </td>
            <td className="customTD">{ele.fullName ? ele.fullName : "-"}</td>
            <td className="customTD">{ele.email ? ele.email : "-"}</td>
            <td className="customTD text-center">{ele.mobileNo ? ele.mobileNo : "-"}</td>

            <td className="text-center">
              <NavLink
                to={{ pathname: "/viewconsoler", state: { id: ele.id } }}
                className="border-0 bg-transparent btn btn-primary btn-sm pointer"
              >
                <i className="fa fa-eye text-primary  customfaICON "></i>
              </NavLink>
            </td>
            <td>
              <NavLink
                to={{ pathname: "/editconsoler", state: { id: ele.id } }}
                className="border-0 bg-transparent btn btn-primary btn-sm pointer"
              >
                <i className="fa fa-edit themeText customfaICON "></i>
              </NavLink>
            </td>
            <td>
              <button
                className="border-0 bg-transparent btn btn-primary btn-sm pointer"
                onClick={() => deleteConsoler(ele.id)}
              >
                <i className="fa fa-trash themeDangerText customfaICON "></i>
              </button>
            </td>
          </tr>
        ))}
    </tbody>
  );

  useEffect(() => {
    getConsolers(0);
  }, []);

  return isAuthenticated ? (
    showloading ? (
      <div className="m-0 p-0">
        <Loading />
      </div>
    ) : (
      <div className="col-md-12 grid-margin stretch-card pt-4 p-4">

        <div className="HeaderText">
          <h1 className="themeText">Consolers</h1>
        </div>
        <div className="card mt-4 themeBoxShadow rounded-4">
             
          <div className="card-body">
            <h4 className="card-title"></h4>
            
            <div className="card-description">
              <div className="d-flex align-items-center justify-content-between my-4">              
              {List.length>0 && <button
                     onClick={() => deleteConsolers()}
                    className="themeBackground btn btn-primary btn-sm font-weight-bold rounded-4 py-2  px-3"
                  >Delete All
                 </button>}
              <ul className="nav nav-pills " id="pills-tab" role="tablist">
                <NavLink
                  to={{ pathname: "/addConsoler" }}
                  className="themeBackground btn btn-primary btn-sm ms-auto font-weight-bold rounded-4 py-2 d-flex px-3"
                >
                  <i className="fa fa-plus text-white customfaICON me-2"></i>Add Consoler
                </NavLink>
                {/* <button
                  className="btn btn-success btn-sm"
                  style={{ marginLeft: "auto", marginRight: "2%" }}
                  onClick={() => setVisible(true)}
                  >
                  Consoler Availability
                </button> */}
              </ul>
              </div>
              {showloading ? (
                <div className="m-0 p-0">
            
                  <Loading />
                </div>
              ) : List.length > 0 ? (
                <div
                  className="table-responsive  tableScrolling"
                  style={{ overflowX: "inherit" }}
                >
                  <div className="tab-content" id="pills-tabContent">
                    <table className="table h-100">
                      <thead>
                        <tr>
                          {/* <th className="radiusthmainstart customTH "> No</th> */}
                          <th className="customTH"
                          >
                          <input
                            type="checkbox" checked={selectall} onChange={handleSelectAll} />
                          </th>

                          <th className="customTH"> Name </th>
                          <th className="customTH"> Email</th>
                          <th className="customTH text-center pe-5"> Mobile </th>
                          <th className="customTH text-center"> View</th>
                          <th className="customTH"> Edit</th>
                          <th className="customTH "> Delete</th>
                        </tr>
                      </thead>
                      {table}
                    </table>
                  </div>
                </div>
              ) : (
                <div className="text-center w-100">
                  <h2 className="mt-5">Records not found</h2>
                </div>
              )}
            </div>
            {List.length>0 &&  <div className="btn-group">
              <button className="pagesize btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {pagesize}
              </button>
              <div className="dropdown-menu">
                <a className="dropdown-item pointer" onClick={() => reloadData(25)}>25</a>
                <a className="dropdown-item pointer" onClick={() => reloadData(50)}>50</a>
                <a className="dropdown-item pointer" onClick={() => reloadData(100)}>100</a>
              </div>

            </div>}
          </div>

          <div className='ms-auto mt-3'>

            {List.length > 0 ?
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationbtns"}
                previousLinkClassName={"prevBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationbtnsActive"}
                forcePage={pagenumber}
              />

              : ""}
          </div>
        </div>
        {/* {visible && (
          <div className="model">
            <div className="box">
              <DatePicker
                selectsStart
                onChange={(date) => dateHandele(date)}
                inline={true}
              />
              <div className="selectedDate">
                <h5>selected Dates :-</h5>
                {startDates && startDates.map((ele)=>{
                  return <div>{ele}</div>
                })}
              </div>
              <button className="btn btn-primary buttons" onClick={submitDate}>Submit Detes</button>
            </div>
          </div>
        )} */}
        {/* <Footer/> */}
      </div>
    )
  ) : (
    <Redirect to="/" />
  );
}
