import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Login from './components/auth/Login'
import Signup from './components/auth/Signup'
import AddConsoler from './components/sidecontent/consoler/AddConsoler';
import ViewConsoler from './components/sidecontent/consoler/ViewConsoler';
import ConsolerList from './components/sidecontent/consoler/ConsolerList';
import Appointment from './components/sidecontent/appointment/Appointment'
import Support from './components/sidecontent/Support';
import Main from './components/main/Main';
import Dashboard from './components/sidecontent/Dashboard';
import Forgetpass from './components/auth/Forgetpass';
import Enquiry from './components/sidecontent/enquiry/Enquiry';
import Viewcontact from './components/sidecontent/enquiry/Viewcontact';
import EditAppointment from './components/sidecontent/appointment/EditAppointment';
import ViewAppointment from './components/sidecontent/appointment/ViewAppointment';
import { ToastContainer, toast } from 'react-toastify';
import ReviewsList from './components/sidecontent/reviews/ReviewsList';

function App() {
  const isAuthenticated = localStorage.getItem("loggedin");
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/dashboard">
            <Main component={<Dashboard />} />
          </Route>
          <Route exact path="/consolerlist">
            <Main component={<ConsolerList />} />
          </Route>
          <Route exact path="/reviewslist">
            <Main component={<ReviewsList />} />
          </Route>
          <Route exact path="/appointments">
            <Main component={<Appointment />} />
          </Route>
          <Route exact path="/enquiries">
            <Main component={<Enquiry />} />
          </Route>
          <Route exact path="/viewenquery/:ID">
            <Main component={<Viewcontact />} />
          </Route>
          <Route exact path="/editappointment/:ID">
            <Main component={<EditAppointment />} />
          </Route>
          <Route exact path="/addconsoler">
            <Main component={<AddConsoler />} />
          </Route>
          <Route exact path="/editconsoler">
            <Main component={<AddConsoler />} />
          </Route>
          <Route exact path="/viewconsoler">
            <Main component={<ViewConsoler />} />
          </Route>
          <Route exact path="/viewappointment">
            <Main component={<ViewAppointment />} />
          </Route>
          {/* <Route exact path="/forget-password">  viewappointment
            <Forgetpass />
          </Route> */}
          <Route exact path="/support">
            <Main component={<Support />} />
          </Route>
          {/* <Route exact path="/signup" component={Signup} /> */}

          <Route exact path="*">
            {isAuthenticated ?
              <Redirect to="/appointments" />
              : <Redirect to="/" />}
          </Route>

        </Switch>

      {/* <ToastContainer/> */}
      </div>
    </Router>
  );
}

export default App;
