import React from 'react'
import Button from "@material-ui/core/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState,useEffect } from 'react';
import axios from 'axios';
import Footer from '../../Footer/Footer';
import { useParams } from 'react-router-dom';

function EditAppointment() {
    const [selectedAppointment,setselectedAppointment] = useState();
    
    const {ID } = useParams();
    const getAppointment = async () => {
        console.log(ID);
        let res = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/admin/appoinment/getappoinment', { id:ID });
        console.log(res)
        // if (res.data.statuscode == 200) {
        //     let data = res.data
        //     console.log(data);
        //     // setselectedAppointment({ fullName: data.fullName, email: data.email, mobileNo: data.mobileNo, description: data.description, profileimage: data.image })
        // }
    }
    useEffect(()=>{
        getAppointment();
    },[]);
  return (
    <div className="col-md-12 grid-margin stretch-card">
    <div className="card">
    <div className="row">
        <div className="col-lg-6">
          1
        </div>
        <div className="col-lg-6">
          2
        </div>
      </div>
      
        <div className="card-body">
            <h4 className="card-title">Edit Appointment</h4>
            <p className="card-description"></p>
            <form className="" >
                <div className="my-5">
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className="form-control"
                            id="floatingInputtextname"
                            name="fullName"
                            // onChange={handleChange}
                            placeholder="Name"
                        />
                        <label htmlFor="floatingInputemail">Name</label>
                        {/* <label htmlFor="floatingInputtextname">Name</label>
                        {error && !admin.fullName && (
                         <p className="text-danger">Please Enter Name  </p> */}
                    </div>

                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className="form-control"
                            id="floatingInputemail"
                            name="email"
                            // onChange={handleChange}
                            placeholder="email"
                        />
                        <label htmlFor="floatingInputemail">Email</label>

                    </div>
                    {/* {error && !admin.email && (
                        <p className="text-danger">Please Enter Email Address </p>
                        )} {valid ? <p className="text-danger">Please Enter valid Emailaddress</p>:""} */}
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className="form-control"
                            id="floatingInputemail"
                            name="mobileNo"
                            // onChange={handleChange}
                            placeholder="Contact details"
                            // onKeyDown={(e) => isNumber(e)}
                        />
                        <label htmlFor="floatingInputemail">Mobile</label>

                    </div>
                    {/* {(error && !admin.mobileNo )&& (
                         <p className="text-danger">Please Enter Mobile Number </p>
                     )} */}
                </div>
                <hr />
                <button type="submit" className="btn btn-primary me-2">
                    Submit
                </button>
            </form>
        </div>
    </div>
    {/* <Footer/> */}
    <ToastContainer
        position="top-center"
        autoClose={2100}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={"dark"}
    />
</div>
  )
          

    
}

export default EditAppointment