import React from 'react'
import { Redirect } from 'react-router-dom'
export default function Support() {
    const isAuthenticated = localStorage.getItem("loggedin");
    return (isAuthenticated ?
        <main>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="card  mt-5">
                            <div className="card-header"><h3 className="text-center font-weight-light my-4">Contact us</h3></div>
                            <div className="card-body">
                                <h5> Please see our website to complete the on-line form or send us an email to:
                                    <br />
                                    <br />
                                    <br />
                                    <a href="http://support@focusvisa.net" target='_blank'> support@focusvisa.net</a></h5>
                            </div>
                            <div className="card-footer text-center py-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main> : <Redirect to='/' />
    )
}
