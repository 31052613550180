import React from 'react'

export default function Footer() {
  return (
    <div className='row text-center'>
        <div className='col-lg-12 mt-4'>
            <small className='themeText font-weight-bold'><small className='text-blue'><i className="fa fa-copyright"></i>&nbsp; Copyright {new Date().getFullYear()} FocusVisa. All Rights Reserved.</small></small>
        </div>
    </div>
  )
}
