import React from 'react'
import { Link, NavLink, Redirect } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert';
import swal from "sweetalert";
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from "../../loading/Loading";
import ReactPaginate from 'react-paginate'
import Footer from '../../Footer/Footer';
import '../../css/pagination.css'

export default function Enquiry() {
  const isAuthenticated = localStorage.getItem("loggedin");
  let [allConatct, setAllContact] = useState([]);
  let [selectall, setselectall] = useState(false);
  const [showloading, setLoading] = useState(true);
  let [pagesize, setpagesize] = useState(25);
  const [length, setLength] = useState(null)
  let [pagenumber, setPagenumber] = useState(0)
  const get = (pagenumber) => {
    setLoading(true)
    axios.post(process.env.REACT_APP_SERVER_URL+`/api/fetchallcontact`, {
      "page": pagenumber + 1,
      "limit": pagesize
    }).then(async (responce) => {
      setLoading(false)
      const enquiryData = await responce.data.contact;
      let data = enquiryData.map(item => ({ ...item, selected: false }))
      setAllContact(data);
    
      setLength(responce.data.totalCount)
      // if(enquiryData.length == 0 && pagenumber >= 1){
      //   setLoading(true);
      //   get(pagenumber - 1);
      //   setPagenumber(pagenumber - 1);
      // } 
    }).catch(error => console.log(error))
  }
  let pageCount = Math.ceil(length / pagesize)
  const changePage = ({ selected }) => {
    console.log(selected)
    setPagenumber(selected)
    pagenumber = selected
    get(selected)
    setLoading(true)
  }
  const reloadData = (size) => {
    pagesize = size
    pagenumber = 0
    setPagenumber(0)
    setpagesize(size)
    get(0)
  }

  const handleSelectAll = () => {
    if (selectall) {
      setAllContact(allConatct.map((record) => ({ ...record, selected: false })));
    } else {
      setAllContact(allConatct.map((record) => ({ ...record, selected: true })));
    }
    setselectall(!selectall)
  };
  const handleSelectRecord = (id) => {
    let data=
    allConatct.map((record) =>
      record._id === id ? { ...record, selected: !record.selected } : record
    )
    setAllContact(data)
    allConatct=data
    let selectedObject= allConatct.filter(item => item.selected)
     const selectedIds = selectedObject.map(obj => obj._id);
     if(selectedIds.length==allConatct.length){
       setselectall(true)
       selectall=true
     }else{
       setselectall(false)
       selectall=false
     }
  };

  const deleteAppointments = () => {
    let selectedObject = allConatct.filter(item => item.selected);
    const selectedIds = selectedObject.map(obj => obj._id);
    if (selectedIds.length === 0) {
      swal("Please select at least one enquiry!");
      return;
    }
    swal({
      text: "You want to delete selected enquiry ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      console.log(selectedIds)
      if (willDelete) {
        let res = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/delete-many-contacts",
          { ids: selectedIds }
        );
        if (res.data.statuscode === 200) {
          setPagenumber(0)
          pagenumber = 0
          get(0);
          swal("Selected enquiries have been deleted!", {
            icon: "success",
          });
        }
      } else {
        swal("Selected enquiry are safe!");
      }
    });
  };


  useEffect(() => {
    get(0);
  }, []);

  const deleteEnquiry = (id) => {
    console.log(id);
    swal({
      // title: "Are you sure?",
      text: "You want to delete this Entry",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          let res = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/deleteContact', { id: id });
          if (res.data.statuscode == 200) {
            get(pagenumber);
          }
          swal("Your Entry has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your Entry is safe!");
        }
      });

  }
  const table = <tbody>
    {allConatct && allConatct.map((ele, i) => (
      <tr key={i}>
        {/* <td>{pagenumber * 5 + i + 1}</td> */}

        <td>
         
          <input
                type="checkbox"
                checked={ele.selected}
                onChange={() => handleSelectRecord(ele._id)}
              />
        </td>

        <td>{ele.fullName ? ele.fullName : "-"}</td>
        <td>{ele.email ? ele.email : "-"}</td>
        <td className='text-center'> {ele.mobileNo ? ele.mobileNo : '-'}</td>

        <td className='text-center'>
          <NavLink to={{ pathname: "/viewenquery/" + ele._id, state: { id: ele.id } }} className="btn btn-default btn-sm themeText" ><i className="fa fa-eye customfaICON themeText"></i></NavLink>
        </td>
        <td>
          <button className="btn btn-default btn-sm  pointer themeText" onClick={() => deleteEnquiry(ele._id)}><i className="fa fa-trash themeDangerText customfaICON"></i></button>
        </td>

      </tr>
    ))}
  </tbody>

  return isAuthenticated ? (
    showloading ? (
      <div className="m-0 p-0">
        <Loading />
      </div>
    ) : (
      <div className="col-md-12 grid-margin stretch-card pt-4 p-4">
        <div className="HeaderText">
          <h1 className="themeText">Enquiry</h1>
        </div>
        <div className="card  mt-4 themeBoxShadow rounded-4">
          <div className="card-body" >
            <div className="card-description">
            {allConatct.length>0 && <button
                     onClick={() => deleteAppointments()}
                    className="themeBackground btn btn-primary btn-sm ms-auto font-weight-bold rounded-4 py-2  px-3"
                  >Delete All
                  </button>}
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">


                {/* {/ <NavLink to={{ pathname: "/addConsoler" }} className="btn btn-primary btn-sm" style={{ marginLeft: "auto", marginRight: "2%" }} >Add Consoler</NavLink> /} */}
              </ul>
              {showloading ?
                <div className="m-0 p-0">
                  <Loading />
                </div> :
                allConatct.length > 0 ? <div className='table-responsive tableScrolling' >
                  <div className="tab-content" id="pills-tabContent">
                    <table className='table table h-100'>

                      <thead>
                        <tr>
                          {/* <th className="radiusthmainstart customTH "> No</th> */}
                          <th className="customTH"
                          >
                            <input
                          type="checkbox" checked={selectall} onChange={handleSelectAll} />
                          </th>
                          <th className="customTH"> Name </th>
                          <th className="customTH"> Email</th>
                          <th className="customTH text-center pe-5"> Mobile </th>
                          <th className="customTH text-center"> View</th>
                          <th className="customTH "> Delete</th>
                        </tr>
                      </thead>
                      {table}
                    </table>
                  </div>
                </div> : <div className='text-center w-100'><h2 className='mt-5'>Records not found</h2></div>}
             {allConatct.length>0 && <div className="btn-group">
                <button className="pagesize btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {pagesize}
                </button>
                <div className="dropdown-menu">
                  <a className="dropdown-item pointer" onClick={() => reloadData(25)}>25</a>
                  <a className="dropdown-item pointer" onClick={() => reloadData(50)}>50</a>
                  <a className="dropdown-item pointer" onClick={() => reloadData(100)}>100</a>
                </div>

              </div>}
            </div>
          </div>
          <div className='ms-auto mt-3'>
            {allConatct.length > 0 ? <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationbtns"}
              previousLinkClassName={"prevBtn"}
              nextLinkClassName={"nextBtn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationbtnsActive"}
              forcePage={pagenumber}
            /> : ""}
          </div>
        </div>
        {/* <div className="card">
        <div className="card-body">
          <h4 className="card-title">Consoler</h4>
         
          <p className="card-description">Add Consoler</p>
        </div>
      </div> */}
        {/* <Footer/> */}
      </div>
    )
  ) : (
    <Redirect to="/" />
  );

}
