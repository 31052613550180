import React, { useState, useEffect } from "react";
// import { Formik, Form, Field } from 'formik';
import Loading from "../../loading/Loading";
import Button from "@material-ui/core/Button";
import { useHistory, Redirect } from "react-router-dom";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./consiler.css";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { MobileDatePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
// import Table from 'react-bootstrap/Table'
import userImg from "../../../image/user.png";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import Select from "react-select";
export default function AddConsoler() {
  const moment = require("moment");
  const LeavSloat = [];
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  // date related states
  // const [selectedDate, setSelectedDate] = useState(null);
  let [newdate, setnewdate] = useState(moment(moment()).add(1, "days"));

  let timeZones = [
    {
      label: "(GMT-12:00) International Date Line West",
      country: "",
      timezone: "Etc/GMT+12",
    },
    {
      label: "(GMT-11:00) Midway Island, Samoa",
      country: "Samoa",
      timezone: "Pacific/Apia",
    },
    {
      label: "(GMT-10:00) Hawaii",
      country: "United States",
      timezone: "Pacific/Honolulu",
    },
    {
      label: "(GMT-09:00) Alaska",
      country: "United States",
      timezone: "America/Anchorage",
    },
    {
      label: "(GMT-08:00) Pacific Time (US & Canada)",
      country: "United States",
      timezone: "America/Los_Angeles",
    },
    {
      label: "(GMT-08:00) Tijuana, Baja California",
      country: "Mexico",
      timezone: "America/Tijuana",
    },
    {
      label: "(GMT-07:00) Arizona",
      country: "United States",
      timezone: "America/Phoenix",
    },
    {
      label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
      country: "Mexico",
      timezone: "America/Chihuahua",
    },
    {
      label: "(GMT-07:00) Mountain Time (US & Canada)",
      country: "United States",
      timezone: "America/Denver",
    },
    {
      label: "(GMT-06:00) Central America",
      country: "",
      timezone: "America/Belize",
    },
    {
      label: "(GMT-06:00) Central Time (US & Canada)",
      country: "United States",
      timezone: "America/Chicago",
    },
    {
      label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
      country: "Mexico",
      timezone: "America/Mexico_City",
    },
    {
      label: "(GMT-06:00) Saskatchewan",
      country: "Canada",
      timezone: "America/Regina",
    },
    {
      label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
      country: "Colombia",
      timezone: "America/Bogota",
    },
    {
      label: "(GMT-05:00) Eastern Time (US & Canada)",
      country: "United States",
      timezone: "America/New_York",
    },
    {
      label: "(GMT-05:00) Indiana (East)",
      country: "United States",
      timezone: "America/Indiana/Indianapolis",
    },
    {
      label: "(GMT-04:30) Caracas",
      country: "Venezuela",
      timezone: "America/Caracas",
    },
    {
      label: "(GMT-04:00) Atlantic Time (Canada)",
      country: "Canada",
      timezone: "America/Halifax",
    },
    {
      label: "(GMT-04:00) Manaus",
      country: "Brazil",
      timezone: "America/Manaus",
    },
    {
      label: "(GMT-04:00) Santiago",
      country: "Chile",
      timezone: "America/Santiago",
    },
    {
      label: "(GMT-03:30) Newfoundland",
      country: "Canada",
      timezone: "America/St_Johns",
    },
    {
      label: "(GMT-03:00) Brasilia",
      country: "Brazil",
      timezone: "America/Sao_Paulo",
    },
    {
      label: "(GMT-03:00) Buenos Aires, Georgetown",
      country: "Argentina",
      timezone: "America/Argentina/Buenos_Aires",
    },
    {
      label: "(GMT-03:00) Greenland",
      country: "Greenland",
      timezone: "America/Godthab",
    },
    {
      label: "(GMT-03:00) Montevideo",
      country: "Uruguay",
      timezone: "America/Montevideo",
    },
    {
      label: "(GMT-02:00) Mid-Atlantic",
      country: "",
      timezone: "Atlantic/South_Georgia",
    },
    {
      label: "(GMT-01:00) Cape Verde Is.",
      country: "Cape Verde",
      timezone: "Atlantic/Cape_Verde",
    },
    {
      label: "(GMT-01:00) Azores",
      country: "Portugal",
      timezone: "Atlantic/Azores",
    },
    {
      label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
      country: "Morocco",
      timezone: "Africa/Casablanca",
    },
    {
      label:
        "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
      country: "United Kingdom",
      timezone: "Europe/London",
    },
    {
      label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      country: "Germany",
      timezone: "Europe/Berlin",
    },
    {
      label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      country: "Slovakia",
      timezone: "Europe/Bratislava",
    },
    {
      label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
      country: "France",
      timezone: "Europe/Paris",
    },
    {
      label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      country: "Croatia",
      timezone: "Europe/Zagreb",
    },
    {
      label: "(GMT+01:00) West Central Africa",
      country: "",
      timezone: "Africa/Lagos",
    },
    { label: "(GMT+02:00) Amman", country: "Jordan", timezone: "Asia/Amman" },
    {
      label: "(GMT+02:00) Athens, Bucharest, Istanbul",
      country: "Greece",
      timezone: "Europe/Athens",
    },
    {
      label: "(GMT+02:00) Beirut",
      country: "Lebanon",
      timezone: "Asia/Beirut",
    },
    { label: "(GMT+02:00) Cairo", country: "Egypt", timezone: "Africa/Cairo" },
    {
      label: "(GMT+02:00) Harare, Pretoria",
      country: "South Africa",
      timezone: "Africa/Johannesburg",
    },
    {
      label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      country: "Finland",
      timezone: "Europe/Helsinki",
    },
    {
      label: "(GMT+02:00) Jerusalem",
      country: "Israel",
      timezone: "Asia/Jerusalem",
    },
    {
      label: "(GMT+02:00) Minsk",
      country: "Belarus",
      timezone: "Europe/Minsk",
    },
    {
      label: "(GMT+02:00) Windhoek",
      country: "Namibia",
      timezone: "Africa/Windhoek",
    },
    {
      label: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
      country: "Iraq",
      timezone: "Asia/Baghdad",
    },
    {
      label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
      country: "Russia",
      timezone: "Europe/Moscow",
    },
    {
      label: "(GMT+03:00) Nairobi",
      country: "Kenya",
      timezone: "Africa/Nairobi",
    },
    { label: "(GMT+03:30) Tehran", country: "Iran", timezone: "Asia/Tehran" },
    {
      label: "(GMT+04:00) Abu Dhabi, Muscat",
      country: "United Arab Emirates",
      timezone: "Asia/Dubai",
    },
    { label: "(GMT+04:00) Baku", country: "Azerbaijan", timezone: "Asia/Baku" },
    {
      label: "(GMT+04:00) Port Louis",
      country: "Mauritius",
      timezone: "Indian/Mauritius",
    },
    {
      label: "(GMT+04:00) Tbilisi",
      country: "Georgia",
      timezone: "Asia/Tbilisi",
    },
    {
      label: "(GMT+04:00) Yerevan",
      country: "Armenia",
      timezone: "Asia/Yerevan",
    },
    {
      label: "(GMT+04:30) Kabul",
      country: "Afghanistan",
      timezone: "Asia/Kabul",
    },
    {
      label: "(GMT+05:00) Ekaterinburg",
      country: "Russia",
      timezone: "Asia/Yekaterinburg",
    },
    {
      label: "(GMT+05:00) Islamabad, Karachi, Tashkent",
      country: "Pakistan",
      timezone: "Asia/Karachi",
    },
    {
      label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      country: "India",
      timezone: "Asia/Kolkata",
    },
    {
      label: "(GMT+05:45) Kathmandu",
      country: "Nepal",
      timezone: "Asia/Kathmandu",
    },
    {
      label: "(GMT+06:00) Astana, Dhaka",
      country: "Bangladesh",
      timezone: "Asia/Dhaka",
    },
    {
      label: "(GMT+06:00) Sri Jayawardenepura",
      country: "Sri Lanka",
      timezone: "Asia/Colombo",
    },
    {
      label: "(GMT+06:00) Almaty, Novosibirsk",
      country: "Russia",
      timezone: "Asia/Novosibirsk",
    },
    {
      label: "(GMT+06:30) Yangon (Rangoon)",
      country: "Myanmar (Burma)",
      timezone: "Asia/Yangon",
    },
    {
      label: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
      country: "Thailand",
      timezone: "Asia/Bangkok",
    },
    {
      label: "(GMT+07:00) Krasnoyarsk",
      country: "Russia",
      timezone: "Asia/Krasnoyarsk",
    },
    {
      label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      country: "China",
      timezone: "Asia/Shanghai",
    },
    {
      label: "(GMT+08:00) Kuala Lumpur, Singapore",
      country: "Malaysia",
      timezone: "Asia/Kuala_Lumpur",
    },
    {
      label: "(GMT+08:00) Perth",
      country: "Australia",
      timezone: "Australia/Perth",
    },
    { label: "(GMT+08:00) Taipei", country: "Taiwan", timezone: "Asia/Taipei" },
    {
      label: "(GMT+08:00) Irkutsk, Ulaan Bataar",
      country: "Mongolia",
      timezone: "Asia/Ulaanbaatar",
    },
    {
      label: "(GMT+09:00) Osaka, Sapporo, Tokyo",
      country: "Japan",
      timezone: "Asia/Tokyo",
    },
    {
      label: "(GMT+09:00) Seoul",
      country: "South Korea",
      timezone: "Asia/Seoul",
    },
    {
      label: "(GMT+09:00) Yakutsk",
      country: "Russia",
      timezone: "Asia/Yakutsk",
    },
    {
      label: "(GMT+09:30) Adelaide",
      country: "Australia",
      timezone: "Australia/Adelaide",
    },
    {
      label: "(GMT+09:30) Darwin",
      country: "Australia",
      timezone: "Australia/Darwin",
    },
    {
      label: "(GMT+10:00) Brisbane",
      country: "Australia",
      timezone: "Australia/Brisbane",
    },
    {
      label: "(GMT+10:00) Canberra, Melbourne, Sydney",
      country: "Australia",
      timezone: "Australia/Sydney",
    },
    {
      label: "(GMT+10:00) Hobart",
      country: "Australia",
      timezone: "Australia/Hobart",
    },
    {
      label: "(GMT+10:00) Guam, Port Moresby",
      country: "Papua New Guinea",
      timezone: "Pacific/Port_Moresby",
    },
    {
      label: "(GMT+10:00) Vladivostok",
      country: "Russia",
      timezone: "Asia/Vladivostok",
    },
    {
      label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
      country: "Russia",
      timezone: "Asia/Magadan",
    },
    {
      label: "(GMT+12:00) Auckland, Wellington",
      country: "New Zealand",
      timezone: "Pacific/Auckland",
    },
    {
      label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
      country: "Fiji",
      timezone: "Pacific/Fiji",
    },
    {
      label: "(GMT+13:00) Nuku'alofa",
      country: "Tonga",
      timezone: "Pacific/Tongatapu",
    },
  ];

  let [timeSlots, setTimeSlots] = useState([
    { time: "09:00", Global: "09:00", selected: false },
    { time: "09:30", Global: "09:30", selected: false },
    { time: "10:00", Global: "10:00", selected: false },
    { time: "10:30", Global: "10:30", selected: false },
    { time: "11:00", Global: "11:00", selected: false },
    { time: "11:30", Global: "11:30", selected: false },
    { time: "12:00", Global: "12:00", selected: false },
    { time: "12:30", Global: "12:30", selected: false },
    { time: "01:00", Global: "13:00", selected: false },
    { time: "01:30", Global: "13:30", selected: false },
    { time: "02:00", Global: "14:00", selected: false },
    { time: "02:30", Global: "14:30", selected: false },
    { time: "03:00", Global: "15:00", selected: false },
    { time: "03:30", Global: "15:30", selected: false },
    { time: "04:00", Global: "16:00", selected: false },
    { time: "04:30", Global: "16:30", selected: false },
  ]);

  const resetTimeSlots = () => {
    timeSlots = timeSlots.map((item) => {
      return { ...item, selected: false };
    });
    setTimeSlots(timeSlots);
  };

  const [admin, setAdmin] = useState({
    ID: "",
    fullName: "",
    email: "",
    mobileNo: "",
    description: "",
    image: "",
    country: "",
    timeZone: "",
  });

  const isAuthenticated = localStorage.getItem("loggedin");
  const history = useHistory();
  const [showloading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [startDates, setStartDates] = useState([]);
  const [visible, setVisible] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [validMobile, setValidMobile] = useState(false);
  const [slots, setslots] = useState();
  const [isChecked, setIsChecked] = useState(false);
  let [times, setTimes] = useState([]);
  const [array, setArray] = useState([]);
  const [isUpdate, setUpdate] = useState(false);
  const [error, setError] = useState(false);
  //const [LeavSloats, setLeavSloats] = useState([]);
  let [selectedLeavSloats, setselectedLeavSloats] = useState([]);
  const [selecteDate, setselecteDate] = useState(moment(new Date()));
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeZonee, setTimeZonee] = useState(
    timeZones.find((obj) => obj.timezone === "Australia/Sydney")
  );
  const [consolerCountry, setconsolerCountry] = useState("Australia");
  const [consoleId, setConsolerId] = useState("");
  const [defaultDate, setDefaultDate] = useState("");

  const handleTime = (time, isSelected) => {
    const seldate = moment(newdate);
    const timee = moment(time, "HH:mm");
    const dateTime = createCustomDateTime(newdate, time, timeZonee.timezone);
    console.log(isSelected);
    if (!isSelected) {
      if (!selectedLeavSloats.includes(dateTime.toString())) {
        setselectedLeavSloats((prew) => [...prew, dateTime.toString()]);
      }
    } else {
      setselectedLeavSloats((prevSelectedLeavSloats) =>
        prevSelectedLeavSloats.filter((item) => item !== dateTime.toString())
      );
    }

    const updatedTimeSlots = timeSlots.map((slot) => {
      if (slot.Global === time) {
        return { ...slot, selected: !slot.selected };
      }
      return slot;
    });

    timeSlots = updatedTimeSlots;
    setTimeSlots(updatedTimeSlots);
  };

  const createCustomDateTime = (date, time, fromTimeZone) => {
    // debugger
    try {
      var dateTime = moment.tz(
        `${date} ${time}`,
        "YYYY-MM-DD HH:mm",
        fromTimeZone
      );
      return dateTime;
    } catch (e) {
      console.log("An error occurred:", e);
    }
  };

  const sourceTimeZone = "Australia/Sydney";
  const [{ alt, src }, setImg] = useState({
    src: userImg,
    // src: 'http://party.gvmtechnologies.com:5555/consoler/profile_avatar.png',
    alt: "Upload an Image",
  });
  const filteredOptions = timeZones.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleProfileChange = async (e) => {
    if (e.target.value) {
      const file = e.target.files[0];
      setImg({
        src: URL.createObjectURL(file),
        alt: file.name,
      });
      setAdmin({ ...admin, [e.target.name]: e.target.files[0] });
    }
  };

  const handleChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const onDateChange = (value) => {
    resetTimeSlots();
    console.log("object", value);
    setupSelectedDate(value);
  };
  const setupSelectedDate = (seldate) => {
    console.log("first", seldate);
    const selDateTime = seldate.format("YYYY-MM-DD");
    setnewdate(selDateTime);
    selectedLeavSloats.map(async (m) => {
      const dateTime = createCustomDateTime(
        moment(m).format("YYYY-MM-DD"),
        m?.split(" ")[4],
        timeZonee.timezone
      );

      console.log(
        dateTime.format("YYYY-MM-DD"),
        "==",
        selDateTime,
        dateTime.format("YYYY-MM-DD") == selDateTime
      );
      if (dateTime.format("YYYY-MM-DD") == selDateTime) {
        const index = timeSlots.findIndex(
          (slot) => slot.Global == dateTime.format("HH:mm")
        );
        console.log(index);
        if (index !== -1) {
          timeSlots[index].selected = true;
          setUpdate(true);
        }
      }
    });
    timeSlots = timeSlots;
    setTimeSlots(timeSlots);
  };
  useEffect(() => {
    if (isUpdate) {
      setTimeSlots((prevTimeSlots) => [...prevTimeSlots]);
    } else {
      if (history.location.state) {
        getConsoler(history.location.state.id);
        setConsolerId(history.location.state.id);
      }
    }
  }, [isUpdate]);

  const getConsoler = async (id) => {
    setLoading(true);
    let res = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/api/admin/getconsoler",
      { id }
    );
    setLoading(false);
    if (res.data.statuscode == 200) {
      let data = res.data.data;
      setTimeZonee(data.timeZone);
      setAdmin({
        ID: data.id,
        fullName: data.fullName,
        email: data.email,
        mobileNo: data.mobileNo,
        description: data.description,
        profileimage: data.image,
        country: data.country,
        timeZone: data.timeZone,
      });
      if (data.image) {
        setImg({ src: data.image });
      }
      // mapHolidays(data.holidays)
      setselectedLeavSloats(data.holidays);
      selectedLeavSloats = data.holidays;
      // setUpdate(true)
      // console
      setupSelectedDate(newdate);
    }
  };

  const mapHolidays = (holidays) => {
    holidays.map((LeaveDates) => {
      console.log(LeaveDates, "LeaveDates");
      setselectedLeavSloats((prew) => [
        ...prew,
        moment(LeaveDates, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").format(
          "YYYY-MM-DD HH:mm"
        ),
      ]);
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    //Validation for input filed
    var regex =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    if (!admin.fullName) {
      setError(true);
      return 1;
    }
    if (!admin.mobileNo) {
      setError(true);
      return 1;
    }
    if (isNaN(admin.mobileNo)) {
      setValidMobile(true);
      return 1;
    }
    if (!admin.email) {
      setError(true);
      return 1;
    } else {
      setError(false);
    }
    if (!regex.test(admin.email)) {
      setValid(true);
      return 1;
    } else {
      setValid(false);
    }
    if (!admin.description) {
      setError(true);
      return 1;
    }
    let matchedObject = timeZones.find(
      (obj) => obj.timezone === timeZonee.timezone
    );

    console.log(matchedObject, "MatchObject");
    let formData = new FormData();
    formData.append("fullName", admin.fullName);
    formData.append("mobileNo", admin.mobileNo);
    formData.append("email", admin.email);
    formData.append("description", admin.description);
    formData.append("image", admin.image);
    formData.append("timeZone", JSON.stringify(matchedObject));
    formData.append("country", consolerCountry);
    formData.append("leaveSlots", JSON.stringify(selectedLeavSloats)); //JSON.stringify(updateArray));

    if (history.location.state) {
      formData.append("id", history.location.state.id);
      let res = await axios.put(
        process.env.REACT_APP_SERVER_URL + "/api/admin/updatconsoler",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        }
      );
      // addHolidays(history.location.state.id)
      if (res.data.statuscode == 200) {
        history.push("/consolerlist");
      }
    } else {
      let res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/admin/createconsoler",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        }
      );

      if (res.data.statuscode == 200) {
        if (res.data.status == "fail") {
          toast(res.data.message);
        } else {
          history.push("/consolerlist");
        }
      }

      if (res.data.message) {
        toast(res.data.message);
      }
    }
  };

  return isAuthenticated ? (
    showloading ? (
      <div className="m-0 p-0">
        <Loading />
      </div>
    ) : (
      <div className="col-md-12 grid-margin stretch-card mt-0 ps-4 pe-4 p-3">
        <div className="d-flex mt-4 ">
          <a
            className="bg-transparent border-0 btn btn-primary w-auto rounded-5 ps-0 font-weight-bolder"
            onClick={() => history.push("/consolerlist")}
          >
            <i
              className="fa fa-arrow-left themeText leftIcon mt-1"
              aria-hidden="true"
            ></i>
          </a>
          <h2 className="themeText ">
            {" "}
            {history.location.state ? "Edit" : "Add"} Consoler{" "}
          </h2>
        </div>
        <div className="card mt-4 themeBoxShadow rounded-4">
          <div className="row flexColumn">
            <form className="d-flex flexColumn" onSubmit={onSubmit}>
              <div className="col-lg-3">
                <div className="HeaderText d-flex  py-2 pb-3 flex-column">
                  <span className="ms-0 font-weight-bold text-center mt-4 themeText">
                    Profile image
                  </span>
                  <div className="text-center m-4 mt-4">
                    {admin && (
                      <img
                        height="150"
                        width="150"
                        className="d-inline-block rounded-circle border border-2 mt-2"
                        src={src}
                        alt={alt}
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      onChange={handleProfileChange}
                      name="image"
                    />
                    <br />
                    <br />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        className="themeBackground text-white rounded-4 font-weight-bold"
                        component="span"
                      >
                        <i className="fa fa-upload pe-2"></i> Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 paddingTopRespo profilePadding ">
                <div className="row flexColumn">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInputtextname"
                        name="fullName"
                        value={admin.fullName ? admin.fullName : ""}
                        onChange={handleChange}
                        placeholder="Name"
                      />
                      <label htmlFor="floatingInputtextname">Name</label>
                      {error && !admin.fullName && (
                        <p className="text-danger">Please Enter Name </p>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInputemail"
                        name="email"
                        value={admin.email ? admin.email : ""}
                        onChange={handleChange}
                        placeholder="Email Address"
                      />
                      <label htmlFor="floatingInputemail">Email Address</label>
                      {error && !admin.email && (
                        <p className="text-danger">
                          Please Enter Email Address{" "}
                        </p>
                      )}{" "}
                      {valid && (
                        <p className="text-danger">
                          Please Enter valid Email address
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row flexColumn paddingTopRespo">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="floatingInputemail"
                        name="mobileNo"
                        value={admin.mobileNo ? admin.mobileNo : ""}
                        onChange={handleChange}
                        placeholder="Mobile Number"
                        // onKeyDown={(e) => isNumber(e)}
                      />
                      <label htmlFor="floatingInputnumber">Mobile Number</label>
                      {error && !admin.mobileNo && (
                        <p className="text-danger">
                          Please Enter Mobile Number{" "}
                        </p>
                      )}
                      {validMobile ? (
                        <p className="text-danger">Please Enter Number Only </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <div className="">
                      <div className="form-floating mb-0">
                        <input
                          type="text"
                          rows={7}
                          className="h-100 form-control mt-2"
                          id="floatingInputtext"
                          name="country"
                          value={consolerCountry}
                          onChange={(e) => setconsolerCountry(e.target.value)} //handleChange}
                          placeholder="Country"
                        />
                        <label htmlFor="floatingInputtext">Country</label>
                        {error && !consolerCountry && (
                          <p className="text-danger">Please Enter Country </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="rounded-full">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        className="pt-0"
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label={"Holidays / Short leave (From)"}
                            openTo="day"
                            variant="filled"
                            className="pt-0 rounded-4 w-100"
                            disablePast={true}
                            defaultValue={dayjs(newdate)}
                            onChange={(value) => {
                              onDateChange(value);
                            }}
                            renderInput={(params) => <p {...params} />}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>

                  {/* <div className="col pt-2">
                    <div className="">
                      <div className="form-floating ">
                        <select
                          className="form-select rounded-4 shadowoff"
                          // id="floatingSelectLanguage"
                          // name="timeZone"
                          value={timeZonee}
                          disabled = {consoleId === "" ? "" : "disabled"}

                          // onChange={handleChange}
                          onChange={(e) => setTimeZonee(e.target.value)}
                        >
                          <option value="">Select Timezone</option>
                          {timeZones.map((ele) => (
                            <option key={ele.label} value={ele.timezone}>
                              {ele.label}{" "}
                              {ele.country ? "(" + ele.country + ")" : ""}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="floatingSelectLanguage">Timezone</label>
                        {error && !admin.timeZone && (
                          <p className="text-danger">Please Select Timezone </p>
                        )}
                      </div>
                    </div>
                  </div> */}

                  <div className="col pt-2">
                    <div className="">
                      <div className="form-floating">
                        <Select
                          className="form-select rounded-4 shadowoff"
                          value={timeZonee}
                          onChange={(option) => setTimeZonee(option)}
                          options={filteredOptions}
                          onInputChange={(inputValue) =>
                            setSearchQuery(inputValue)
                          }
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.timezone}
                          placeholder="Search..."
                        />
                        <label htmlFor="floatingSelectLanguage">Timezone</label>
                        {error && !timeZonee && (
                          <p className="text-danger">Please Select Timezone </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>

                <div className="row">
                  <div className="col mt-3">
                    <div>
                      <div className="timeSelecter w-auto">
                        <div className="d-flex ">
                          <label
                            htmlFor="floatingInputnumber"
                            className="timeslots h-100"
                          >
                            Select Time-Slots
                          </label>
                          &nbsp;&nbsp;
                        </div>
                        {console.log("timeSlots:", timeSlots)}
                        {timeSlots &&
                          timeSlots.map((time, i) => {
                            return (
                              <div className="hoverSlot" key={i}>
                                <input
                                  type="checkbox"
                                  checked={time.selected}
                                  onChange={(e) =>
                                    handleTime(time.Global, time.selected)
                                  }
                                />{" "}
                                {time.time}
                                {/* {moment
                                    .tz(
                                      moment.utc(now_utc).set({
                                        hour: moment
                                          .utc(time.time, "HH:mm")
                                          .get("hour"),
                                        minute: moment
                                          .utc(time.time, "HH:mm")
                                          .get("minute"),
                                      }),
                                      sourceTimeZone
                                    )
                                    .clone()
                                    .tz(sourceTimeZone)
                                    .format("h:mm A")} */}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <textarea
                        type="text"
                        rows={7}
                        className="h-100 form-control mt-2 "
                        id="floatingInputtext"
                        name="description"
                        value={admin.description ? admin.description : ""}
                        onChange={handleChange}
                        placeholder="Description"
                      />
                      <label htmlFor="floatingInputtext">Description</label>
                      {error && !admin?.description && (
                        <p className="text-danger">Please Enter Description </p>
                      )}
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="themeBackground text-white btn btn-primary me-2 d-flex ms-auto rounded-4 mt-3 font-weight-bold px-4"
                >
                  <i className="fa fa-send mt-1 pe-2"></i>
                  Submit
                </button>
              </div>
            </form>
            <div></div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={2100}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={"dark"}
        />
        {/* <Footer/> */}
      </div>
    )
  ) : (
    <Redirect to="/" />
  );
}
