import React, { useState, useEffect } from "react";
import "./ReviewsList.css";
import { IconButton, FormControlLabel, Switch, Modal, CircularProgress } from "@material-ui/core";
import { Link, NavLink, Redirect } from 'react-router-dom'
import { Favorite, FavoriteBorder, Check, Close } from "@material-ui/icons";
import Loading from "../../loading/Loading";
export default function ReviewsList() {
  const [modalOpen, setModalOpen] = useState(false);
  const [reviews, setReviews] = useState([]);
  const isAuthenticated = localStorage.getItem("loggedin");
  const [isChecked, setIsChecked] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showloading, setLoading] = useState(true);
  const handleToggle = (review) => {
    setSelectedReview(review);
    setConfirmOpen(true);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleConfirm = () => {
    // Send the update request
    updateReview(selectedReview.id, !selectedReview.is_approved);
    setConfirmOpen(false);
  };

  const handleCancel = () => {
    setSelectedReview(null);
    setConfirmOpen(false);
  };

  const updateReview = async (reviewId, isApprove) => {
    try {
      setLoading(true)
      const response = await fetch(process.env.REACT_APP_SERVER_URL+ "/api/admin/updateReview", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: reviewId,
          is_approve: isApprove.toString(),
        }),
      });
      if (response.ok) {
        console.log("Review Data Updated Successfully");
        fetchReviews(); // Fetch reviews again after the update
      } else {
        console.log("Error updating review:", response.status);
      }
      setIsLoading(false); // Set loading state to false after reviews are fetched
    } catch (error) {
      console.log("Error updating review:", error);
      setIsLoading(false); // Set loading state to false if there's an error

    }
  };

  const fetchReviews = async () => {
    try {
      setLoading(true)
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/admin/getallReview", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      if (data.status === "success") {
        setLoading(false)
        const fetchedReviews = data.data.map((review) => ({
          ...review,
          isChecked: review.is_approved,
        }));
        setReviews(fetchedReviews);
      } else {
        console.log("Error fetching reviews:", data.error);
      }
      setIsLoading(false); // Set loading state to false after reviews are fetched
    } catch (error) {
      console.log("Error fetching reviews:", error);
      setIsLoading(false); // Set loading state to false if there's an error
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  const truncatedDescription = (description) =>
    description.length > 50 ? `${description.substring(0, 50)}...` : description;

  const handleReadMore = (review) => {
    setSelectedReview(review);
    setModalOpen(true);
  };

  const handleReadLess = () => {
    setModalOpen(false);
  };

  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <Loading />
        </div>
      ) : (
    
  
   
        <div className="col-md-12 grid-margin stretch-card pt-4 p-4">
          <div className="HeaderText">
            <h1 className="themeText">Reviews</h1>
          </div>
              
        <Modal open={modalOpen} onClose={handleReadLess}>
        <div className="modal-content">
          <div className="modal-body">
            <p>{selectedReview && selectedReview.description}</p>
          </div>
          <div className="modal-footer">
            <button onClick={handleReadLess} className="brnBg btn btn-default ">
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal open={confirmOpen} onClose={handleCancel}>
<div className="modal-content">
<div className="modal-body">
<p>Are you sure you want to update this review?</p>
</div>
<div className="modal-footer">
<button onClick={handleConfirm} className="brnBg border-0 btn btn-primary ">
Yes
</button>
<button onClick={handleCancel} className="border-0 btn btn-secondary">
No
</button>
</div>
</div>
</Modal>
          <div className="card mt-4 themeBoxShadow rounded-4">
            <div className="card-body">
              <h4 className="card-title border-0"></h4>
              <div className="card-description">
                <div className="table-responsive overflow-auto tableScrolling ">
                  <div className="tab-content" id="pills-tabContent">
                    <table className="table table h-100">
                      <thead>
                        <tr>
                          {/* <th className="customTH">
                            <input type="checkbox" />
                          </th> */}
                          <th className="customTH">Name</th>
                          <th className="customTH">Email</th>
                          <th className="customTH">Review</th>
                          <th className="customTH text-center">Rating</th>
                          <th className="customTH">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reviews.map((review) => (
                          <tr key={review.id}>
                            {/* <td>
                              <input type="checkbox" />
                            </td> */}
                            <td>{review.fullName}</td>
                            <td>{review.email}</td>
                            <td className="description">
                              {truncatedDescription(review.description)}
                              {review.description.length > 50 && (
                                <span onClick={() => handleReadMore(review)} className=" p-0 pointer text-primary">
                                  Read More
                                </span>
                              )}
                 
                            </td>
                            <td className="text-center">{review.rating}</td>
                            <td>
                              <label className="toggle-switch">
                                <input
                                  type="checkbox"
                                  checked={review.is_approved}
                                  onChange={() => handleToggle(review)}
                                />
                                <span className={`slider ${review.is_approved ? "checked" : ""}`}>
                                  <span className="round-button"></span>
                                  <span className="toggle-mark">{review.is_approved ? "✔" : "✕"}</span>
                                </span>
                              </label>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    
    </div>
  );
}
