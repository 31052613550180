import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
export default function Dashboard() {
    const userid = localStorage.getItem('user')
    const isAuthenticated = localStorage.getItem("loggedin");
    const [details, setdetails] = useState({ Reports: "", profiledetail: "" })
    const getdetails = () => {
        axios.post(`${process.env.REACT_APP_API_URI}/user-report/get-reports-count`, { userid: userid }).then((res) => {
            if (res.data.statuscode == 200) {
                setdetails(res.data.data)
            }
        })
    }
    useEffect(() => {
    }, [])
    return (
        isAuthenticated ?
            <main>
                <div className="container-fluid px-4">
                    <h1 className="mt-4">Dashboard</h1>
                    <ol className="breadcrumb mb-4">
                        <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                   
                </div>
            </main> : <Redirect to='/' />
    )
}
