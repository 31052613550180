import React, { Component } from 'react'
import loading from './gifMain.gif'
export default class Loading extends Component {
    render() {
        return (
            <div className='d-flex justify-content-center align-items-center loaderArea'>
                <img src={loading} alt="Loading" className='' height="100" width="100" />
            </div>
        )
    }
}
