import React, { useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import axios from "axios";
import StripeCheckout from "react-stripe-checkout";
import Zoom from "react-reveal/Zoom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Subscription(props) {
  const history = useHistory();
  const [product, setproduct] = useState(false);
  const isAuthenticated = localStorage.getItem("loggedin");

  useEffect(() => {
    setproduct(props.plans)
  }, [])
  // "#ffc254"

  const closePopup = () => {
    props.DisablePopup()
  }
  return (
    isAuthenticated ? product &&
      <div style={{ backgroundColor: "rgba(0,0,0,0.5)", position: "fixed", left: "0", top: "0", width: "100%", height: "100%" ,zIndex:1111}} className="overflow-auto">
        <Zoom top >
          <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
              <main>
                <div className="container">
                  <div className="row justify-content-center">
                    {product.length > 0 ?
                      product.map((plan, i) =>

                        <Card
                          plan={plan}
                          btn_color={plan.btn_color}
                          top_color={plan.top_color}
                          bg_color={plan.bg_color}
                          bottom_color={plan.bottom_color}
                          features={plan.features}
                          key={i}
                          closePopup={closePopup}
                        />) : <div className="card shadow-lg border-0 rounded-lg my-5 "><h1 className="text-center mx-auto my-5" >No plans available</h1>

                        <button onClick={() => history.push("/")} className="btn w-25 mx-auto btn-primary btn-sm " >&#8592; Back to Login</button>
                        <br /><br /><br /><br /><br /><br /><br />
                      </div>
                    }
                  </div>
                </div>
              </main>
            </div>
          </div>
        </Zoom>
        <ToastContainer
          position="top-center"
          autoClose={2100}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={'dark'}
        />
      </div> : <Redirect to='/' />
  );
}


export function Card(props) {
  const history = useHistory()
  const token = localStorage.getItem('token')
  const [product, setproduct] = useState(props.plan);

  const Close = () => {
    props.closePopup()
  }

  const makePayment = async (stripetoken) => {
    const res = await axios.post(`${process.env.REACT_APP_API_URI}/subscription/payment`, { stripetoken, product }, { headers: { 'x-access-token': token } })
    console.log("res", res)
    if (res.data.statuscode == 200) {
      alert('Subscription completed! A temporary password and instructions emailed to your registered email')
      Close()
    } else if (res.data.statuscode == 302) {
      alert('Already Subscribed')
      Close()
    } else {
      alert(res.data.msg)
    }
  }
  return (
    <div className="col-lg-4">
      <div className="card shadow-lg border-0 rounded-lg mt-5">
        <div
          className="card-header"
          style={{ backgroundColor: props.top_color }}
        >
          <h3 className="text-center font-weight-light my-4">
            Subscription Plan
          </h3>
        </div>
        <div
          className="text-center card-body"
          style={{ backgroundColor: props.bg_color }}
        >
          <h4>Paid Plan</h4>
          <h3>{product.description}</h3>
          <h3>in {product.price}$</h3>
          <hr />
          <br />
          <div>
            {/* {props.features && props.features.map((feature,i)=>
          <h5 key={i}> ✔ {feature}</h5>
          )     
          } */}
          </div>
          <br />
          <StripeCheckout
            stripeKey={process.env.REACT_APP_STRIPE_KEY}
            token={makePayment}
            name="Stripe Payment gateway"
            description='new payment'
            amount={product.price * 100}
            shippingAddress
            billingAddress
          >
            <button
              className="btn btn-success"
              style={{ backgroundColor: props.btn_color }}
            >
              Buy on just {product.price}$
            </button>
          </StripeCheckout>
        </div>
        <div
          className="card-footer text-end py-3"
          style={{ backgroundColor: props.bottom_color }}
        >
          <div className="small" onClick={Close}>
            <p className="btn btn-outline-success">
              Skip
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subscription;