import React, { useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import axios from "axios";
import Subscription from "./Subscription";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Img from '../../image/bg4.JPG'
import Loading from '../loading/Loading'
function Signup() {
  const isAuthenticated = localStorage.getItem("loggedin");
  const [admin, setAdmin] = useState({
    companyname: "",
    fullname: "",
    emailaddress: "",
    registrationnumber: "",
    phonenumber: "",
    address: "",
    country: "",
  });
  const history = useHistory();
  const [countries, setCountry] = useState();
  const [showloading, setLoading] = useState(false)
  const [showSubscribe, setShowSubscribe] = useState(false)
  //get countries
  const getcountry = () => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/country/getcountry`)
      .then((res) => {
        if (res.data.data2.length > 0) {
          setCountry(res.data.data2);
        }
      });
  };
  useEffect(() => {
    getcountry();
  }, []);

  const Created = async (e) => {
    e.preventDefault();
    if(admin.fullname=="" || admin.fullname==" "){
      toast("Full name is required field")
      return 0
    }else if(admin.emailaddress.search("@")==-1 ){
      toast("Email address is not valid")
      return 0
    }else if(admin.country=="" || admin.country==" "){
      toast("country is required field")
      return 0
    }
    if(admin.phonenumber.length==0){
      toast("Phone number is required field")
    }else if(admin.phonenumber.length<10){
    toast("Phone number must be atlist 10 digits.")
    }else if(admin.phonenumber.length>15){
      toast("Phone number is too long.")
    }else{
      setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_URI}/user-registration/user-email-verification`, {
        emailaddress: admin.emailaddress, admin
      })
      .then((res) => {
        if (res.data.statuscode == 200) {
          // setShowSubscribe(true)
          setLoading(false)
          alert('Subscription completed! A temporary password and instructions emailed to your registered email')
          history.push('/')
        } else {
          toast(res.data.message)
        }
      });
    }
  };
  const handleChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };
  return (!isAuthenticated ?
    <div id="layoutAuthentication">
      <div id="layoutAuthentication_content"  style={{backgroundImage:`url(`+Img+")",backgroundSize:"cover"}}>
        <main >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                  <div className="card-header">
                    <h3 className="text-center font-weight-light my-4">
                      Create Account
                    </h3>
                  </div>
                  <div className="card-body">
                    <form onSubmit={Created}>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInputtextcompany"
                          name="companyname"
                          value={admin.companyname}
                          onChange={handleChange}
                          placeholder="companyname"
                        />
                        <label htmlFor="floatingInputtextcompany">Company name</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInputtextname"
                          name="fullname"
                          value={admin.fullname}
                          onChange={handleChange}
                          placeholder="fullname"
                        />
                        <label htmlFor="floatingInputtextname">Full name<span style={{ color: 'red' }}>*</span></label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInputtextregnum"
                          name="registrationnumber"
                          value={admin.registrationnumber}
                          onChange={handleChange}
                          placeholder="registrationnumber"
                        />
                        <label htmlFor="floatingInputtextregnum">Registration number</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInputemail"
                          name="emailaddress"
                          value={admin.emailaddress}
                          onChange={handleChange}
                          placeholder="email"
                        />
                        <label htmlFor="floatingInputemail">Email address<span style={{ color: 'red' }}>*</span></label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="number"
                          className="form-control"
                          id="floatingInputclassmobile"
                          name="phonenumber"
                          value={admin.phonenumber}
                          onChange={handleChange}
                          placeholder="Phone number"
                          maxLength={15}
                          minLength={10}
                        />
                        <label htmlFor="floatingInputclassmobile">Phone number<span style={{ color: 'red' }}>*</span></label>
                      </div>
                      <div className="form-floating mb-3">
                        <textarea
                          type="text"
                          className="form-control"
                          id="floatingInputtextaddress"
                          name="address"
                          value={admin.address}
                          onChange={handleChange}
                          placeholder="Address"
                        />
                        <label htmlFor="floatingInputtextaddress">Address</label>
                      </div>
                      <div className="form-floating mb-3">
                        <select
                          className="form-select"
                          onChange={handleChange}
                          name="country"
                          aria-label="Default select example"
                          value={admin.country}
                        >
                          <option value="">Country</option>
                          {countries &&
                            countries.map((ele, i) => (
                              <option key={i} value={ele.name}>
                                {ele.name}
                              </option>
                            ))}
                        </select>
                        <label htmlFor="floatingSelectGrid">Country<span style={{ color: 'red' }}>*</span></label>
                      </div>
                       <div style={{textAlign:"justify"}}><input className="form-check-input" id="inputRememberPassword" type="checkbox" required /><span> To ensure you’re getting the best offers and pricing, Roof Inspection Pro and our providers you select may need to
contact you by text/call. By checking this box, you agree to these communications, some of which may be automated. You can opt-out anytime.Your use of our services is also subject to our Terms of Service and Privacy Policy.
<br/><br/>
By signing up you agree to our Terms of Use and Privacy Policy. </span></div>
                      <div className="mt-4 mb-0">
                        <div className="d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            // onClick={Created}
                            type="submit"
                          >
                            Signup
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center py-3">
                    <div className="small">
                      <Link to="/">Have an account? Go to login</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="layoutAuthentication_footer">
        <footer className="py-4 bg-dark mt-auto">
          <div className="container-fluid px-4">
            <div className="d-flex align-items-center justify-content-between small">
              <div className="text-muted">
                Copyright &copy; support@roofinspectionpro.net
              </div>
              <div className="text-muted">
              From Australia&nbsp;&nbsp; :<a href="tel:1300 727 054" style={{ textDecoration:"none"}}>1300 727 054</a><br/>
              From Overseaas:<a href="tel:+61 478 566 998" style={{ textDecoration:"none"}}>+61 478 566 998</a>
               
              </div>
            </div>
          </div>
        </footer>
        <ToastContainer
          position="top-center"
          autoClose={2100}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={'dark'}
        />
      </div>
        </main>
      </div>
   
      {showSubscribe && <Subscription />}
    </div> : <Redirect to='/' />
  );
}

export default Signup;
