import React, { useState, useEffect } from "react";
import { NavLink, Redirect } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Loading from "../../loading/Loading";
import ReactPaginate from 'react-paginate'
import '../../css/pagination.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaTrash } from 'react-icons/fa';
import Footer from "../../Footer/Footer";

export default function Appointment() {
  const userid = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  let [pagesize, setpagesize] = useState(25);
  let [pagenumber, setPagenumber] = useState(0)
  const isAuthenticated = localStorage.getItem("loggedin");
  let [selectall, setselectall] = useState(false);
  const [showloading, setLoading] = useState(true);
  let [List, setList] = useState([]);
  const [length, setLength] = useState(null)
  const getConsolers = async (pagenumber) => {
    setLoading(true)
    let res = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/admin/appoinment/fetchallappoinment', { page: pagenumber + 1, limit: pagesize })
    setLoading(false)
    console.log("res", res);
    if (res.data.statuscode == 200) {
      let data = res?.data?.appoinment.map(item => ({ ...item, selected: false }))
      setList(data);
      setLength(res.data.totalCount)
      if (res.data.appoinment.length == 0 && pagenumber >= 1) {
        setLoading(true);
        getConsolers(pagenumber - 1);
        setPagenumber(pagenumber - 1);
      }
    }
  }
  // console.log("List: ",List)
  let pageCount = Math.ceil(length / pagesize)
  const changePage = ({ selected }) => {
    console.log(selected)
    setPagenumber(selected)
    pagenumber = selected
    getConsolers(selected)
    setLoading(true)
  }
  const reloadData = (size) => {
    pagesize = size
    pagenumber = 0
    setPagenumber(0)
    setpagesize(size)
    getConsolers(0)
  }
  const handleSelectAll = () => {
    if (selectall) {
      setList(List.map((record) => ({ ...record, selected: false })));
    } else {
      setList(List.map((record) => ({ ...record, selected: true })));
    }
    setselectall(!selectall)
  };
  const handleSelectRecord = (id) => {
    let data =
      List.map((record) =>
        record._id === id ? { ...record, selected: !record.selected } : record
      )
    setList(data)
    List = data
    let selectedObject = List.filter(item => item.selected)
    const selectedIds = selectedObject.map(obj => obj._id);
    if (selectedIds.length == List.length) {
      setselectall(true)
      selectall = true
    } else {
      setselectall(false)
      selectall = false
    }
  };

  const deleteAppointments = () => {
    let selectedObject = List.filter(item => item.selected);
    const selectedIds = selectedObject.map(obj => obj._id);
    if (selectedIds.length === 0) {
      swal("Please select at least one appointment!");
      return;
    }
    swal({
      text: "You want to delete selected appointments ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let res = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/admin/appoinment/delete-many-appointments",
          { ids: selectedIds }
        );
        if (res.data.statuscode === 200) {
          setPagenumber(0)
          pagenumber = 0
          getConsolers(0);
          swal("Selected appointments have been deleted!", {
            icon: "success",
          });
        }
      } else {
        swal("Selected appointments are safe!");
      }
    });
  };

  const deleteConsoler = (id) => {
    swal({
      // title: "Are you sure?",
      text: " Are you sure you want to delete this Appointment ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          let res = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/admin/appoinment/deleteAppoinment",
            { id: id }
          );
          if (res.data.statuscode == 200) {
            getConsolers(pagenumber);
          }
          swal("Your Appointment has been deleted!", {
            icon: "success",

          });
        } else {
          swal("Your Appointment detils is safe!");
        }
      });
  };


  const table = <tbody>
    {List && List.map((ele, i) => (
      <tr key={i} className="customTR">
        {/* <td className="customTD">{pagenumber * 5 + i + 1}</td> */}
        <td>
          <input
            type="checkbox"
            checked={ele.selected}
            onChange={() => handleSelectRecord(ele._id)}
          />
        </td>
        <td className="customTD">{ele.fullName ? ele.fullName : "-"}</td>
        <td className="customTD">{ele.email ? ele.email : "-"}</td>
        <td className="customTD text-center">{ele.mobileNo ? ele.mobileNo : '-'}</td>

        <td className="text-center">
          <NavLink to={{ pathname: "/viewappointment", state: { id: ele._id } }} className="btn btn-default btn-sm themeText" ><i className="fa fa-eye customfaICON themeText"></i></NavLink>
        </td>
        {/* <td>
          <NavLink to={`/editappointment/${ele._id}`} className="btn btn-primary btn-sm" >Edit</NavLink>
        </td> */}
        <td>
          <button className="btn btn-default btn-sm  pointer themeText" onClick={() => deleteConsoler(ele._id)}> <i className="fa fa-trash themeDangerText customfaICON"></i> </button>
        </td>

      </tr>
    ))}
  </tbody>


  useEffect(() => {
    getConsolers(0);
  }, []);

  return isAuthenticated ? (
    showloading ? (
      <div className="m-0 p-0">
        <Loading />
      </div>
    ) : (
      <div className="col-md-12 grid-margin stretch-card pt-4 p-4">

        <div className="HeaderText">
          <h1 className="themeText">Appointments</h1>
        </div>

       <div className="card mt-4 themeBoxShadow rounded-4">
          <div className="card-body" >
           {List.length>0 &&  <button
              onClick={() => deleteAppointments()}
              className="themeBackground btn btn-primary btn-sm ms-auto font-weight-bold rounded-4 py-2  px-3"
            >Delete All
            </button>}
            <h4 className="card-title border-0"></h4>
            <div className="card-description">
              {showloading ? <div className="m-0 p-0">
                <Loading />
              </div> :
                List.length > 0 ? <div className='table-responsive tableScrolling' >
                  <div className="tab-content" id="pills-tabContent">
                    <table className='table table h-100 '>

                      <thead>
                        <tr>
                          {/* <th className="radiusthmainstart customTH "> No</th> */}

                          <th className="customTH"
                          ><input
                              type="checkbox" checked={selectall} onChange={handleSelectAll} />
                          </th>

                          <th className="customTH "> Name </th>
                          <th className="customTH"> Email</th>
                          <th className="customTH text-center pe-5"> Mobile </th>
                          <th className="customTH text-center" width="20"> View</th>
                          {/* <th> Edit</th> */}
                          <th className="customTH "> Delete</th>
                        </tr>
                      </thead>
                      {table}
                    </table>
                  </div>
                </div> : <div className='text-center w-100'><h2 className='mt-5'>Records not found</h2></div>}
                {List.length>0 && <div className="btn-group">
                <button className="pagesize btn-sm border-0 shadow-none text-white dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {pagesize}
                </button>
                <div className="dropdown-menu">
                  <a className="dropdown-item pointer" onClick={() => reloadData(25)}>25</a>
                  <a className="dropdown-item pointer" onClick={() => reloadData(50)}>50</a>
                  <a className="dropdown-item pointer" onClick={() => reloadData(100)}>100</a>
                </div>

              </div>}
            </div>
          </div>

          <div className='ms-auto mt-3'>
            {List.length > 0 ?
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationbtns"}
                previousLinkClassName={"prevBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationbtnsActive"}
                forcePage={pagenumber}
              />

              : ""}
          </div>
        </div>
        {/* <Footer/> */}
      </div>
    )
  ) : (
    <Redirect to="/" />
  );
}
// `${process.env.REACT_APP_API_URI}/UserPic/${admin.profileimage ? admin.profileimage : 'user.png'}`
