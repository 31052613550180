import React, { useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Img from "../../image/bg4.JPG";
import logo from "../../image/Focus_Visa_footer_logo.png";
import Darklogo from "../../image/darkLogo.svg";
import Footer from "../Footer/Footer";
import "../css/Login.css";

function Login() {
  const isAuthenticated = localStorage.getItem("loggedin");
  const [emailerr, setemailerr] = useState(false);
  const [passworderr, setpassworderr] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [admin, setAdmin] = useState({ emailaddress: "", password: "" });
  const [passwordShown, setPasswordShown] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "emailaddress") {
      setemailerr(false);
      setError(false);
    }
    if (e.target.name === "password") {
      setpassworderr(false);
    }
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const Submit = async (e) => {
    e.preventDefault();
    let err = false;
    if (admin.emailaddress === "") {
      setemailerr(true);
      err = true;
    } else {
      var reg = /\S+@\S+\.\S+/;
      if (!reg.test(admin.emailaddress)) {
        setError(true);
        err = true;
      }
    }

    if (!admin.password) {
      setpassworderr(true);
      err = true;
    }
    if (admin.emailaddress && admin.password && !err) {
      setLoading(true);
      try {
        let res = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/admin/auth/adminLogin",
          { email: admin.emailaddress, password: admin.password }
        );
        console.log(res);
        if (res.data.statuscode === 200) {
          localStorage.setItem("loggedin", true);
          history.push("/appointments");
        } else {
          swal({
            title: "Error!",
            text: "Sorry, your email or password is incorrect. Please try again.",
            icon: "error",
            button: "ok",
          });
        }
      } catch (error) {
        swal({
          title: "Error!",
          text: "An error occurred. Please try again later.",
          icon: "error",
          button: "ok",
        });
      }
      setLoading(false);
    }
  };

  return !isAuthenticated ? (
    <>
      <section className="overflow-hidden">
        <div className="row">
          <div className="col-lg-6 respoDnone  justify-content-center align-items-center themeBackground align-items-center login justify-content-center ">
            <div className="">
              <img src={logo} className="" alt="Logo" />
            </div>
          </div>

          <div className="col-lg-6 bg-white">
            <div className="login d-flex align-items-center py-5">

              
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-10 col-xl-8 mx-auto">
                        <div className="card border-0 rounded-lg">
              <div className=" respoDesk">
                <img src={Darklogo} className="w-75" alt="Dark Logo" />
              </div>
              <div className="card-header bg-transparent border-0">
                <h1 className="text-center font-weight-light my-4 font-weight-bolder ">
                  Login
                </h1>
              </div>
              <div className="card-body">
                <form>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      name="emailaddress"
                      value={admin.emailaddress}
                      onChange={handleChange}
                      placeholder="name@example.com"
                    />
                    <label htmlFor="floatingInput">Email address</label>
                    {emailerr && !admin.emailaddress && (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        Email is required
                      </p>
                    )}
                    {error && (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        Email is Invalid.
                      </p>
                    )}
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-control"
                      id="floatingPassword"
                      name="password"
                      value={admin.password}
                      onChange={handleChange}
                      placeholder="Password"
                    />
                    {passworderr && !admin.password && (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        Password is required
                      </p>
                    )}
                    <input
                      type="checkbox"
                      checked={passwordShown}
                      onChange={() => setPasswordShown(!passwordShown)}
                      className="my-3 ms-2"
                    />{" "}
                    <span className="ms-1">Show password</span>
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mt-4 mb-0">
                    <button
                      className="themeBackground btn btn-primary rounded-5 font-weight-bolder py-3 px-5"
                      onClick={Submit}
                      disabled={loading}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        <>
                          <i className="fa fa-sign-in" aria-hidden="true"></i>{" "}
                          Login
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


          {/* <div className="col-lg-6 pt-5 px-5 login ">
            <div className="card border-0 rounded-lg  px-5 ">
              <div className=" respoDesk">
                <img src={Darklogo} className="w-75" alt="Dark Logo" />
              </div>
              <div className="card-header bg-transparent border-0">
                <h1 className="text-center font-weight-light my-4 font-weight-bolder ">
                  Login
                </h1>
              </div>
              <div className="card-body">
                <form>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      name="emailaddress"
                      value={admin.emailaddress}
                      onChange={handleChange}
                      placeholder="name@example.com"
                    />
                    <label htmlFor="floatingInput">Email address</label>
                    {emailerr && !admin.emailaddress && (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        Email is required
                      </p>
                    )}
                    {error && (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        Email is Invalid.
                      </p>
                    )}
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-control"
                      id="floatingPassword"
                      name="password"
                      value={admin.password}
                      onChange={handleChange}
                      placeholder="Password"
                    />
                    {passworderr && !admin.password && (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        Password is required
                      </p>
                    )}
                    <input
                      type="checkbox"
                      checked={passwordShown}
                      onChange={() => setPasswordShown(!passwordShown)}
                      className="my-3 ms-2"
                    />{" "}
                    <span className="ms-1">Show password</span>
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mt-4 mb-0">
                    <button
                      className="themeBackground btn btn-primary rounded-5 font-weight-bolder py-3 px-5"
                      onClick={Submit}
                      disabled={loading}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        <>
                          <i className="fa fa-sign-in" aria-hidden="true"></i>{" "}
                          Login
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
          <ToastContainer
            position="top-center"
            autoClose={2100}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={"dark"}
          />
        </div>
        {/* <Footer/> */}
      </section>
    </>
  ) : (
    <Redirect to="/appointments" />
  );
}

export default Login;
