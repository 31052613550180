import axios from "axios";
import React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./viewContact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Flag } from "@material-ui/icons";
import Footer from "../../Footer/Footer";

function Viewcontact() {
  const history = useHistory();
  const { ID } = useParams();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [message, setMessage] = useState();
  const [subject, setSubject] = useState();
  const [visible, setVisible] = useState(false);
  const [textvalue, setTextVlue] = useState("");
  const [error, setError] = useState(false)
  axios
    .post(process.env.REACT_APP_SERVER_URL + "/api/getcontact", {
      id: ID,
    })
    .then(async (res) => {
      const data = await res.data;
      setName(data.contact.fullName);
      setEmail(data.contact.email);
      setNumber(data.contact.mobileNo);
      setMessage(data.contact.message);
      setSubject(data.contact.subject);
    })
    .catch((err) => console.log(err));

  const back = () => {
    history.push("/enquiries");
  };
  const cancelReplay = () => {
    var modal = document.getElementById('exampleModalCenter');
    modal.classList.remove('show');
    modal.style.display = 'none';
    var backdrop = document.getElementsByClassName('modal-backdrop')[0];
    backdrop.parentNode.removeChild(backdrop);
    document.body.classList.remove('modal-open');
    setVisible(false);
  };
  const setDescription = (e) => {
    setTextVlue(e.target.value)
    setError(false)
  }
  const sendReplay = () => {
    if (textvalue == "") {
      setError(true);
    } else {
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/api/enquiry-response", {
          id: ID,
          text: textvalue,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status == "success") {
            cancelReplay()
            // history.push("/enquiries");
            toast.success("Replay Send Successfully");
          }
        })
        .catch((error) => console.log(error));
    }

  };


  return (
    <>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card pt-4 p-5">
          <div className="HeaderText d-flex">
            <a className="bg-transparent border-0 btn btn-primary w-auto rounded-5 ps-0 font-weight-bolder pointer" onClick={() => back()}>
              <i className="fa fa-arrow-left themeText leftIcon mt-1" aria-hidden="true"></i>
            </a>
            <h2 className="themeText">Enquiry Details</h2>
          </div>
          <div className="card mt-4 themeBoxShadow rounded-4">
            <div className="card-body">
              <div className="">
                <form
                  className="row g-3 pt-4"
                  enctype="multipart/form-data"
                // onSubmit={back}
                >
                  <>

                    <div className="row flexColumn">
                      <div className="col">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                            id="floatingInputtextname"
                            name="fullName"
                            value={name}
                            readOnly
                            placeholder="Full Name"
                          />
                          <label htmlFor="floatingInputtextname">Full Name</label>
                          {/* <span style={{ color: "red" }}>
                        {fullnamerequired && submit ? "fullname is required" : ""}
                      </span> */}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                            id="floatingInputtextemail"
                            name="email"
                            value={email}
                            readOnly
                            placeholder="Email Address"
                          />
                          <label htmlFor="floatingInputtextemail">Email Address</label>
                          {/* <span style={{ color: "red" }}>
                        {fullnamerequired && submit ? "fullname is required" : ""}
                      </span> */}
                        </div>
                      </div>
                    </div>

                    <div className="row flexColumn">
                      <div className="col">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                            id="floatingInputtextnumber"
                            name="number"
                            value={number}
                            readOnly
                            placeholder="Mobile Number"
                          />
                          <label htmlFor="floatingInputtextnumber">Mobile Number</label>
                          {/* <span style={{ color: "red" }}>
                        {fullnamerequired && submit ? "fullname is required" : ""}
                      </span> */}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                            id="floatingInputtextsubject"
                            name="subject"
                            value={subject}
                            readOnly
                            placeholder="Subject"
                          />
                          <label htmlFor="floatingInputtextsubject">Subject</label>
                          {/* <span style={{ color: "red" }}>
                          {/* <span style={{ color: "red" }}>
                        {fullnamerequired && submit ? "fullname is required" : ""}
                      </span> */}
                        </div>
                      </div>
                    </div>

                    <div className="row flexColumn">
                      <div className="col">
                        <div className="form-floating mb-3">
                          <textarea
                            type="text"
                            rows={6}
                            className="h-100 form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                            id="floatingInputmessage"
                            name="message"
                            value={message}
                            readOnly
                            placeholder="Message"
                          />
                          <label htmlFor="floatingInputmessage" className="">Message</label>
                        </div>
                      </div>
                    </div>


                    <div className="d-flex ms-auto justify-content-end">

                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        className="themeBackground btn btn-success me-2 mt-3 rounded-5 px-5 font-weight-bold"
                        onClick={() => setVisible(true)}
                      >
                        Reply
                      </button>

                    </div>
                  </>
                </form>
              </div>
              {visible && (
                // <div className="model">
                //   <div className="box">
                //     <div className="selectedDate">
                //       <div className="form-outline w-100">
                //         <h3 className="head">Description :</h3>
                //         <textarea
                //           className="form-control description"
                //           id="textAreaExample2"
                //           rows="8"
                //           onChange={(e) => setTextVlue(e.target.value)}
                //         ></textarea>
                //         {error && <p className="text-danger">Please Enter Discription for send to Email</p>}
                //       </div>
                //     </div>
                //     <button
                //       className="btn btn-primary click"
                //       onClick={() => sendReplay()}
                //     >
                //       Send
                //     </button>
                //     <button
                //       className="btn btn-secondary click"
                //       onClick={() => cancelReplay()}
                //     >
                //       Cancel
                //     </button>
                //   </div>
                // </div>
                <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Description</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="form-group">
                          <label for="message-text" className="col-form-label">Message:</label>
                          <textarea className="form-control" id="message-text" rows={5} onChange={(e) => setDescription(e)}></textarea>
                          {error && <p className="text-danger">Please Enter Discription for send to Email</p>}
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="themeBackground btn btn-success me-2 mt-3 rounded-5 px-5 " onClick={() => sendReplay()}>Send</button>
                        {/* <button type="button" className="themeBackground btn btn-success me-2 mt-3 rounded-5 px-5 " onClick={() => cancelReplay()}>Cancel</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </>
  );
}

export default Viewcontact;
