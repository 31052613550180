import React, { useState, useEffect } from "react";
import Loading from "../../loading/Loading";
import { useHistory, Redirect, NavLink } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./consiler.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultImage from "../../../image/user.png";
import moment from "moment/moment";
import Footer from "../../Footer/Footer";
export default function ViewConsoler() {
  const isAuthenticated = localStorage.getItem("loggedin");
  const [showloading, setLoading] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const history = useHistory();
  const [startDates, setStartDates] = useState([]);
  const [visible, setVisible] = useState(false);
  const [admin, setAdmin] = useState({
    fullName: "",
    email: "",
    mobileNo: "",
    description: "",
    profileimage: "",
    timeZone:""
  });
  // console.log(admin);
  const getConsoler = async (id) => {
    setLoading(true);
    let res = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/api/admin/getconsoler",
      { id }
    );
    setLoading(false);
    if (res.data.statuscode == 200) {
      let data = res.data.data;
      setAdmin({
        fullName: data.fullName,
        email: data.email,
        mobileNo: data.mobileNo,
        description: data.description,
        profileimage: data.image,
        country: data.country,
        timeZone:data.timeZone.timezone
      });
    }
  };
  const goback = () => {
    history.push("/consolerlist");
  };

  useEffect(() => {
    if (history.location.state.id) {
      getConsoler(history.location.state.id);
      // getHolidays(history.location.state.id);
    } else {
      history.push("/consolerlist");
    }
  }, []);

  return isAuthenticated ? (
    showloading ? (
      <div className="m-0 p-0">
      <Loading />
      </div>
    ) : (
      <div className="col-md-12 grid-margin stretch-card pt-4 p-4">
        <div className=" d-flex justify-content-between">
          <div className="HeaderText d-flex">

        <a className="bg-transparent border-0 btn btn-primary w-auto rounded-5 ps-0 font-weight-bolder pointer" onClick={() => goback()}>
                    <i className="fa fa-arrow-left themeText leftIcon mt-1" aria-hidden="true"></i>  
                    </a>
          <h2 className="themeText">Consoler Details</h2>
          </div>
          <NavLink
                to={{ pathname: "/editconsoler", state: { id: history.location.state.id } }}
                className="border-0 bg-transparent btn btn-primary btn-sm pointer"
              >
                         <i className="fa fa-edit themeText customfaICON "></i>  
              </NavLink>
        </div>

        <div className="card mt-4 themeBoxShadow rounded-4">
          <div className="card-body">
            <div className="row">
              <form className="d-flex p-4 flexColumn" onSubmit={goback}>
                <div className="col-lg-3 align-items-center d-flex flex-column ">
                  <div className="">
                  <div className="pb-3 d-flex text-center justify-content-center ">
             
                    <span className="themeText font-weight-bold text-center">Profile image</span>
                  </div>
                  {admin && (
                    <img
                      height="150"
                      width="150"
                      className="d-inline-block rounded-circle border border-2  mt-4"
                      src={
                        admin.profileimage ? admin.profileimage : defaultImage
                      }
                      alt={admin.profileimage}
                    />
                  )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    name="profileimage"
                  />
                  <br />
                  <br />
                </div>
                <div className="col-lg-9">
                  <div className="row flexColumn">
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputtextname"
                          name="fullName"
                          value={admin.fullName ? admin.fullName : ""}
                          readOnly
                          placeholder="Name"
                        />
                        <label htmlFor="floatingInputtextname">Name</label>
                        {/* <span style={{ color: "red" }}>
                        {fullnamerequired && submit ? "fullname is required" : ""}
                      </span> */}
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputemail"
                          name="email"
                          value={admin.email ? admin.email : ""}
                          readOnly
                          placeholder="email"
                        />
                        <label htmlFor="floatingInputemail">Email Address</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                        id="floatingInputemail"
                        name="mobileNo"
                        value={admin.mobileNo ? admin.mobileNo : ""}
                        readOnly
                        placeholder="Contact details"
                      />
                      <label htmlFor="floatingInputemail" className="ps-4">Mobile</label>
                    </div>
                  </div>
                  <div className="row flexColumn">
                    <div className="form-floating mb-3 col">
                      <input
                        type="text"
                        className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                        id="floatingInputemail"
                        name="country"
                        value={admin.country ? admin.country : ""}
                        readOnly
                        placeholder="Country"
                      />
                      <label htmlFor="floatingInputemail" className="ps-4">Country</label>
                    </div>
                    <div className="form-floating mb-3 col">
                      <input
                        type="text"
                        className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                        id="floatingInputemail"
                        name="country"
                        value={admin.timeZone ? admin.timeZone : ""}
                        readOnly
                        placeholder="Country"
                      />
                      <label htmlFor="floatingInputemail" className="ps-4">Country TimeZone</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating mb-3">
                      <textarea
                        type="text"
                        rows={6}
                        className="h-100 form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                        id="floatingInputtext"
                        name="description"
                        value={admin.description ? admin.description : ""}
                        readOnly
                        placeholder="Description"
                      />
                      <label htmlFor="floatingInputtext" className="ps-4">Description</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {/* <button className="btn btn-primary me-2" >
              Back
            </button> */}
          </div>
        </div>
        {/* <Footer/> */}
      </div>
    )
  ) : (
    <Redirect to="/" />
  );
}
