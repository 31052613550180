import React from "react";
import { Link, useHistory } from "react-router-dom";
import "../css/Navbar.css";
import DarkLogo from "../../image/darkLogo.svg";
// import avtar from "../../image/roundavtar.png";
import defaulImg from '../../image/user.png'


export default function Navbar() {
  const history = useHistory();
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("loggedin");
    localStorage.removeItem("useremail");
    localStorage.removeItem("token");
    history.push("/");
  };
  return (
    <nav className="sb-topnav navbar navbar-expand justify-content-between px-4">
      <Link className="navbar-brand" to="/appointments">
        <img src={DarkLogo} className="img-fluid" />
      </Link>

      <button className="sidebarToggle hamburgerMobile"  id="sidebarToggle">
      <span className="hamburger-bar"></span>
  <span className="hamburger-bar"></span>
  <span className="hamburger-bar"></span>
      </button>

      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        <div className="input-group">
          {/* <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                    <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button> */}
        </div>
      </form>

      <div className="dropdown show ">
        <a
          className="bg-transparent dropdown-toggle text-decoration-none"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
        <img src={defaulImg} className="" width="40" height="40"/>
        <span className="text-black font-weight-bold ps-2 text-decoration-none">Admin</span>
        </a>

        <div className="dropdown-menu mainDRP" aria-labelledby="dropdownMenuLink">
        {/* <a className="dropdown-item" href="#">
        <i className="fa fa-user text-black"></i>
        <span className="ms-3">Profile</span>   
          </a>

          <a className="dropdown-item" href="#">
          <i className="fa fa-gear text-black"></i> 
          <span className="ms-3">
          Settings
            </span>  
          </a> */}
      
          <a className="dropdown-item" href="#" onClick={logout}>
          <i className="fa fa-sign-out text-black"></i> 
          <span className="ms-3">
          Logout
          </span>
          </a>
        </div>
      </div>

      {/* <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
        <div
          style={{ cursor: "pointer" }}
          className="nav-link"
          onClick={logout}
        >
          <div className="sb-nav-link-icon text-white">
            <i className="fas fa-tachometer-alt"></i>
          </div>
          <i className="fa fa-sign-out text-white"></i> Logout
        </div>
      </ul> */}
    </nav>
  );
}

