import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../css/Sidebar.css';

export default function Sidebar() {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false); // New state for sidebar open/close

  useEffect(() => {
    const path = location.pathname;
    setActiveMenu(path);
  }, [location]);


  

  const user = localStorage.getItem('useremail');

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div id="layoutSidenav_nav">
      <nav className={`sb-sidenav accordion ${sidebarOpen ? 'open' : ''}`} id="sidenavAccordion">
      <div className="sidebar-overlay" onClick={closeSidebar}></div> {/* Overlay element */}
        <div className="sb-sidenav-menu">
          <div className="nav">
        
            <Link
              className={`nav-link font-weight-bold customLinkNav ${activeMenu === '/appointments' && 'active'}`}
              to="/appointments"
            >
              <div className="sb-nav-link-icon">
                <i className="fa fa-calendar dashBoardIcon pe-2" aria-hidden="true"></i>
              </div>
              <span className={`icon fa fa-calendar dashBoardIcon pe-2 ${activeMenu === '/appointments' ? 'activeIcon' : ''}`}></span>
              Appointments
            </Link>

            <Link
              className={`nav-link font-weight-bold customLinkNav ${activeMenu === '/consolerlist' && 'active'}`}
              to="/consolerlist"
            >
              <div className="sb-nav-link-icon">
                <i className="fa fa-terminal dashBoardIcon pe-2" aria-hidden="true"></i>
              </div>
              <span className={`icon fa fa-terminal dashBoardIcon pe-2 ${activeMenu === '/consolerlist' ? 'activeIcon' : ''}`}></span>
              Consolers
            </Link>

            <Link
              className={`nav-link font-weight-bold customLinkNav ${activeMenu === '/enquiries' && 'active'}`}
              to="/enquiries"
            >
              <div className="sb-nav-link-icon">
                <i className="fa fa-question-circle dashBoardIcon pe-2" aria-hidden="true"></i>
              </div>
              <span className={`icon fa fa-question-circle dashBoardIcon pe-2 ${activeMenu === '/enquiries' ? 'activeIcon' : ''}`}></span>
              Enquiry
            </Link>

            <Link
              className={`nav-link font-weight-bold customLinkNav ${activeMenu === '/reviewslist' && 'active'}`}
              to="/reviewslist"
            >
              <div className="sb-nav-link-icon">
                <i className="fa fa-star dashBoardIcon pe-2" aria-hidden="true"></i>
              </div>
              <span className={`icon fa fa-star dashBoardIcon pe-2 ${activeMenu === '/reviewslist' ? 'activeIcon' : ''}`}></span>
              Reviews
            </Link>

          </div>
        </div>
      </nav>
      
    </div>
  );
}
