import React, { useState, useEffect } from "react";
import Loading from "../../loading/Loading";
import { useHistory, Redirect } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Footer from "../../Footer/Footer";
import defaultImage from "../../../image/user.png";
import "./appointment.css";

export default function ViewAppointment() {
  const isAuthenticated = localStorage.getItem("loggedin");
  const [showloading, setLoading] = useState(false);
  const history = useHistory();
  const [consolerData, setconsolerData] = useState({
    consolerEmail: "",
    consolerMobileNo: "",
    consolerName: "",
  });
  const [appoinmentData, setappoinmentData] = useState({
    fullName: "",
    email: "",
    mobileNo: "",
    description: "",
    profileimage: "",
  });
  const getappoinment = async (id) => {
    setLoading(true);
    let res = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/api/admin/appoinment/getappoinment",
      { id }
    );
    console.log(res);
    setLoading(false);
    if (res.data.statuscode == 200) {
      let data = res.data.appoinment;
      console.log("data:", data)
      const dateObj = new Date(data.appointment_date);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // Months are zero-based, so we add 1
      const day = dateObj.getDate();
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();
      setappoinmentData({
        fullName: data.fullName,
        email: data.email,
        mobileNo: data.mobileNo,
        appoinmentType: data.appoinmentType,
        notes: data.notes,
        stateApply: data.stateApply,
        state: data.state,
        visaType: data.visaType,
        time: data.appointment_date?.split(" ")[4],
        date: day+'-'+month+'-'+year,
      });
      setconsolerData({
        consolerEmail: data?.consolerDetail?.consolerEmail,
        consolerMobileNo: data?.consolerDetail?.consolerMobileNo,
        consolerName: data?.consolerDetail?.consolerName,
      });
    }
  };
  const goback = () => {
    history.push("/appointments");
  };
  useEffect(() => {
    if (history.location.state.id) {
      getappoinment(history.location.state.id);
    } else {
      history.push("/appointments");
    }
  }, []);

  return isAuthenticated ? (
    showloading ? (
      <div className="m-0 p-0">
        <Loading />
      </div>
    ) : (
      <>
        <div className="row">
          <div className="col-lg-12 p-5 pt-4 ps-5 pe-5">
            <div className="HeaderText d-flex align-items-center py-2 pb-3">
              <div className="d-flex pointer justify-content-start ps-2">
                <a className="bg-transparent border-0 btn btn-primary w-auto rounded-5 ps-0 font-weight-bolder" onClick={() => goback()}>
                  <i className="fa fa-arrow-left themeText leftIcon" aria-hidden="true"></i>
                </a>
              </div>
              <h2 className="themeText ">  Appointment Details</h2>

            </div>
            <div className="bg-white pt-4 px-4 h-100 rounded-4">

              <form className="" onSubmit={goback}>
                <div className="">
                  <div className="row flexColumn">
                    <div className="col ">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputtextname"
                          name="fullName"
                          value={
                            appoinmentData.fullName ? appoinmentData.fullName : ""
                          }
                          readOnly
                          placeholder="Name"
                          style={{ background: "white" }}
                        />
                        <label
                          htmlFor="floatingInputtextname"
                          className="fw-bold"
                        >
                          Name
                        </label>
                        {/* <span style={{ color: "red" }}>
                        {fullnamerequired && submit ? "fullname is required" : ""}
                      </span> */}
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputemail"
                          name="email"
                          value={appoinmentData.email ? appoinmentData.email : ""}
                          readOnly
                          placeholder="Email Address"
                          style={{ background: "white" }}
                        />
                        <label htmlFor="floatingInputemail" className="fw-bold">
                          Email Address
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row flexColumn">
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputemail"
                          name="mobileNo"
                          value={appoinmentData.time ? appoinmentData.time : ""}
                          readOnly
                          placeholder="Contact details"
                          style={{ background: "white" }}
                        />
                        <label htmlFor="floatingInputemail" className="fw-bold">
                          Time
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputemail"
                          name="mobileNo"
                          value={
                            appoinmentData.date
                              ? moment(appoinmentData.date).format("YYYY-MM-DD")
                              : ""
                          }
                          readOnly
                          placeholder="Contact details"
                          style={{ background: "white" }}
                        />
                        <label htmlFor="floatingInputemail" className="fw-bold">
                          Date
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row flexColumn">
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputemail"
                          name="mobileNo"
                          value={
                            appoinmentData.appoinmentType
                              ? appoinmentData.appoinmentType
                              : ""
                          }
                          readOnly
                          placeholder="Contact details"
                          style={{ background: "white" }}
                        />
                        <label htmlFor="floatingInputemail" className="fw-bold">
                          Appoinment Type
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputemail"
                          name="mobileNo"
                          value={
                            appoinmentData.mobileNo ? appoinmentData.mobileNo : ""
                          }
                          readOnly
                          placeholder="Contact details"
                          style={{ background: "white" }}
                        />
                        <label htmlFor="floatingInputemail" className="fw-bold">
                          Mobile
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row flexColumn">
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputemail"
                          name="mobileNo"
                          value={appoinmentData.notes ? appoinmentData.notes : ""}
                          readOnly
                          placeholder="Contact details"
                          style={{ background: "white" }}
                        />
                        <label htmlFor="floatingInputemail" className="fw-bold">
                          Notes
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputemail"
                          name="mobileNo"
                          value={
                            appoinmentData.stateApply
                              ? appoinmentData.stateApply
                              : ""
                          }
                          readOnly
                          placeholder="Contact details"
                          style={{ background: "white" }}
                        />
                        <label htmlFor="floatingInputemail" className="fw-bold">
                          State For You Want To Apply
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row flexColumn">
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputemail"
                          name="mobileNo"
                          value={
                            appoinmentData.visaType ? appoinmentData.visaType : ""
                          }
                          readOnly
                          placeholder="Contact details"
                          style={{ background: "white" }}
                        />
                        <label htmlFor="floatingInputemail" className="fw-bold">
                          Visa Type
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                          id="floatingInputemail"
                          name="mobileNo"
                          value={appoinmentData.state ? appoinmentData.state : ""}
                          readOnly
                          placeholder="Contact details"
                          style={{ background: "white" }}
                        />
                        <label htmlFor="floatingInputemail" className="fw-bold">
                          State (optional)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="HeaderText d-flex align-items-center py-2 pb-3  mt-3">
                <div className="d-flex pointer justify-content-start">
                  <a className="bg-transparent border-0 btn btn-primary w-auto rounded-5 ps-0 font-weight-bolder">
                    {/* <i className="fa fa-arrow-left themeText leftIcon" aria-hidden="true"></i>   */}
                  </a>
                </div>
                <h2 className="themeText ">Consoler Details</h2>
              </div>
              <div className="row flexColumn">
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control backcolor  border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                      id="floatingInputemail"
                      name="mobileNo"
                      value={
                        consolerData.consolerEmail
                          ? consolerData.consolerEmail
                          : ""
                      }
                      readOnly
                      placeholder="Contact details"
                      style={{ background: "white" }}
                    />
                    <label htmlFor="floatingInputemail" className="fw-bold">
                      Consoler Email
                    </label>
                  </div>{" "}
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                      id="floatingInputemail"
                      name="mobileNo"
                      value={
                        consolerData.consolerMobileNo
                          ? consolerData.consolerMobileNo
                          : ""
                      }
                      readOnly
                      placeholder="Contact details"
                      style={{ background: "white" }}
                    />
                    <label htmlFor="floatingInputemail" className="fw-bold">
                      Consoler Mobile No.
                    </label>
                  </div>{" "}
                </div>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control border-top-0 border-left-0 border-right-0 bg-transparent rounded-0"
                  id="floatingInputemail"
                  name="mobileNo"
                  value={
                    consolerData.consolerName ? consolerData.consolerName : ""
                  }
                  readOnly
                  placeholder="Contact details"
                  style={{ background: "white" }}
                />
                <label htmlFor="floatingInputemail" className="fw-bold">
                  Consoler Name
                </label>
              </div>
            </div>
          </div>

          <div className="col-lg-6 p-5 pt-4"></div>
        </div>
        {/* <Footer/> */}
      </>
    )
  ) : (
    <Redirect to="/" />
  );
}
